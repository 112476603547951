<template>
  <div class="container-fluid">
    <Header />
    <div class="history">
      <div class="titleHeader">
        <button
          id="friendgetfriends_history_gotopagefriendgetfriends_button"
          @click="gotoPage('FriendGetFriends')"
          class="nextBtn"
        >
          <img
            src="@/assets/images/arrowBack.svg"
            alt=""
            width="24"
            height="24"
          />
        </button>
        <div class="hText">{{ lbl['main-menu-history-fgf'] }}</div>
      </div>
      <div class="filterContainer nopad">
        <div class="scrollItem">
          <div
            class="filterItem"
            v-for="(item, index) in filterYear"
            :key="'filter' + index"
          >
            {{ item
            }}<img
              id="friendgetfriends_history_removefilter_img"
              @click="removeFilter(item)"
              :src="require('@/assets/images/close.png')"
              alt=""
            />
          </div>
        </div>
        <div :class="['filterMain']">
          <div class="filter">
            {{ lbl['user-permissions-filter'] }}
            <img :src="require('@/assets/images/filter_list.png')" alt="" />
          </div>
          <div class="spaceHover"></div>
          <div class="filterDropdown rightDrop">
            <div class="filterRight">
              <div v-for="item in filter['year'].input" :key="item.text">
                <div
                  v-if="filter['year'].type === 'checkbox'"
                  class="filterList"
                  id="friendgetfriends_history_selectfilter_div"
                  @click="selectFilter(item.text)"
                >
                  <a-checkbox
                    :checked="filter['year'].value.includes(item.text)"
                  >
                    {{ item.text }}
                  </a-checkbox>
                </div>
              </div>
            </div>
            <div class="btnFilter">
              <div
                id="friendgetfriends_history_resetfilter_div"
                @click="resetFilter"
              >
                {{ lbl['user-permissions-reset'] }}
              </div>
              <button
                id="friendgetfriends_history_filtereddatalist_button"
                @click="filteredDataList"
              >
                {{ lbl['user-permissions-ok'] }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-for="(item, index) in filterByYear"
      :key="index + 'datalist'"
      class="mx-auto mt-1 content-outer-container"
    >
      <div class="historytitle">
        {{ item.data.name }}
        <span v-if="item.data.updateDate">
          [{{ formatDateString(item.data.updateDate) }}]
        </span>
      </div>
      <MainForm
        :mode="'history'"
        :data="item.data"
        :isActive="item.isActive"
        :maxPoint="item.maxPoint"
        :imgPreview="item.imgPreview"
        :statsBoxData="item.statsBoxData"
        :row-key="item.RowKey"
      />
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import Header from '@/components/Layout/Header'
import MainForm from './component/MainForm'
import BzbsUserFGF from '@/core/FriendgetFriends/service/BzbsFriendgetFriends'
import moment from 'moment'
import Locale from '@/helper/locale.js'

export default {
  name: 'FriendGetFriendsHistory',
  components: {
    Header,
    MainForm,
  },
  mixins: [Mixin],
  data() {
    return {
      dataList: [],
      filterYear: [],
      filter: {
        year: {
          value: [],
          input: [
            { text: 'All Status', value: 'All Status' },
            { text: 'Draft', value: 'draft' },
            { text: 'Publish', value: 'publish' },
            { text: 'Cancelled', value: 'cancelled' },
            { text: 'Created', value: 'created' },
          ],
          label: 'Status',
          type: 'checkbox',
        },
      },
    }
  },
  computed: {
    filterByYear() {
      if (this.filterYear.length == 0) return this.dataList
      return this.dataList.filter(i => {
        return this.filterYear.includes(
          parseInt(moment(i.data.date[0]).format('YYYY')),
        )
      })
    },
  },
  created() {
    this.handleFooter(true)
    this.getHistoryFGF()
    this.YearOption()
    if (Locale.getLocaleShort() == 'th') {
      moment.locale('th')
    } else {
      moment.locale('en')
    }
  },
  mounted() {
    console.log(this.filterByYear)
  },
  methods: {
    gotoPage(page, mode, id) {
      this.$router.push({
        name: page,
        params: { mode: mode, id: id },
      })
    },
    YearOption() {
      let currentYear = new Date().getFullYear()
      let endYear = currentYear + 50
      this.filter.year.input = Array.from(
        { length: 100 },
        (_, i) => endYear + i * -1,
      ).map(i => {
        return { value: i, text: i }
      })
    },
    selectFilter(item) {
      if (this.filter.year.value.includes(item)) {
        this.filter.year.value = this.filter.year.value.filter(
          filter => filter !== item,
        )
      } else {
        this.filter.year.value.push(item)
      }
    },
    filteredDataList() {
      this.filterYear = [...this.filter.year.value]
    },
    removeFilter(item) {
      this.filter.year.value = this.filter.year.value.filter(
        filter => filter !== item,
      )
      this.filteredDataList()
    },
    resetFilter() {
      this.filter.year.value = []
      this.filterYear = []
    },
    onChangeYear() {
      console.log(1)
    },
    formatDateString(dateString, format = 'DD MMM YYYY', calHours = null) {
      if (dateString) {
        return moment(dateString).add(calHours, 'hours').format(format)
      } else {
        return ''
      }
    },
    getHistoryFGF() {
      this.handleLoading(true)
      BzbsUserFGF.getHistoryFGF()
        .then(res => {
          console.log(res.data)
          this.dataList = res.data
            .filter(i => !i.Active || i.Status == 'Expired')
            .map(i => {
              return this.mapFGFData(i)
            })
          console.log('datalist', this.dataList)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => this.handleLoading(false))
    },
    mapFGFData(item) {
      let dataObject = {
        statsBoxData: [
          {
            icon: `statsbox2`,
            title: 'newuser',
            value: '500',
            unit: 'account',
            bg: '#2196f3',
            key: 'list-newuser',
            rowKey: item.RowKey,
          },
          {
            icon: `statsbox3`,
            title: 'pointspendInviter',
            value: '1630',
            unit: 'point',
            bg: '#ff9800',
            key: 'list-inviter',
            rowKey: item.RowKey,
          },
          {
            icon: `statsbox4`,
            title: 'pointspendInvited',
            value: '1630',
            unit: 'point',
            bg: '#ff9800',
            key: 'list-invited-people',
            rowKey: item.RowKey,
          },
        ],
      }
      dataObject.isActive =
        item.Status === 'Create' || item.Status === 'Publish'
      dataObject.imgPreview = item.ImageUrl
      dataObject.maxPoint = item.MaximumAccount > 0 ? 'Limit' : 'Unlimit'
      let startdateenddate = []
      startdateenddate[0] =
        item.StartDate != 0 && item.StartDate != null
          ? moment(item.StartDate * 1000)
          : ''
      startdateenddate[1] =
        item.ExpireDate != 0 && item.ExpireDate != null
          ? moment(item.ExpireDate * 1000)
          : ''
      dataObject.data = {
        name: item.Name,
        description: item.Description,
        referral_points: item.ReferralPoints,
        user_points: item.UserPoints,
        max_account: item.MaximumAccount,
        joinDays: item.MinimumFriendGetFriendJoinDays,
        joinDaysState: item.MinimumFriendGetFriendJoinDays > 0,
        validateEarnAndRedeem: item.ValidateFriendGetFriendEarnAndRedeem,
        date: startdateenddate,
        updateDate:
          item.UpdateDate != 0 && item.UpdateDate != null
            ? moment(item.UpdateDate * 1000)
            : '',
        image: item.ImageUrl,
        invite_message: item.InviteMessage,
      }
      dataObject.statsBoxData.forEach(el => {
        if (el.title === 'join') el.value = item.TotalInviteCount
        if (el.title === 'newuser') el.value = item.TotalInviteCount
        if (el.title === 'pointspendInviter')
          el.value = item.TotalReferralPoints
        if (el.title === 'pointspendInvited') el.value = item.TotalUserPoints
      })
      return dataObject
    },
  },
}
</script>

<style lang="scss" scoped>
.history {
  max-width: 1400px;
  margin: 30px auto;
  background-color: white;
  border-radius: 5px;
  font-size: 30px;
  color: #212121;
  padding: 20px 30px;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
  .titleHeader {
    display: flex;
    align-items: center;
    .hText {
      margin-left: 24px;
    }
  }
  .filterYear {
    min-width: 120px;
  }
  .nextBtn {
    width: 72px;
    height: 56px;
    border-radius: 4px;
    background-color: white;
    border: solid 1px #757575;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.historytitle {
  font-size: 20px;
  font-weight: 500;
}
.content-outer-container {
  padding: 30px;
  margin-bottom: 30px;
}
.container-fluid {
  background-size: cover !important;
  background-attachment: fixed;
  background-position: center;
}
@media only screen and (max-device-width: 768px) {
  .filterContainer .filterDropdown.rightDrop {
    right: auto;
  }
}
</style>
<style lang="scss">
.history {
  .ant-select-selection__choice {
    height: 32px !important;
  }
  .ant-select-selection__choice__content {
    padding-top: 4px !important;
  }
  .ant-select-selection__choice__remove {
    top: 1px !important;
  }
}
</style>
